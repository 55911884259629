<template>
  <div class="login">
    <div class="container">
      <div class="lt"></div>
      <div class="rt">
        <div class="hd">
          <div :class="{ active: activeIndex === 0 }" @click="changeActive(0)">
            {{ $t('eaee8c4') }}
          </div>
          <div :class="{ active: activeIndex === 1 }" @click="changeActive(1)">
            {{ $t('1255270') }}
          </div>
        </div>
        <div class="bd">
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="rules"
            class="loginForm"
          >
            <el-form-item v-if="loginType === 'userLoginByPhone'" prop="phone">
              <el-input v-model="loginForm.phone" :placeholder="this.$t('6171f9a')" />
            </el-form-item>
            <el-form-item v-else prop="account">
              <el-input v-model="loginForm.account" :placeholder="this.$t('4704c2f')" />
            </el-form-item>
            <el-form-item prop="graphical" class="graphical">
              <el-input
                v-model="loginForm.graphical"
                :placeholder="this.$t('46259e0')"
              />
              <GraphicalCode ref="GraphicalCode" class="uiImg" />
            </el-form-item>
            <el-form-item
              v-if="loginType === 'userLoginByPhone'"
              prop="short"
              class="short"
            >
              <el-input
                v-model="loginForm.short"
                :placeholder="$t('0569ea7')"
              />
              <div class="uiText" @click="handleShortTips">
                {{ showShortTips }}
              </div>
            </el-form-item>
            <el-form-item v-else prop="password">
              <el-input
                v-model="loginForm.password"
                show-password
                :placeholder="this.$t('6b45fda')"
              />
            </el-form-item>
            <div class="reset" @click="goReset">{{ $t('174be1c') }}？</div>

            <el-form-item>
              <el-button
                type="primary"
                class="loginButton"
                :loading="loading"
                @click="submitForm('loginForm')"
              >
                {{ $t('1573214') }}
              </el-button>
            </el-form-item>
          </el-form>
          <div class="des">
            <div>{{ $t('70d86d5') }}？</div>
            <div class="link" @click="goRegister">{{ $t('91f48f9') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api'
import { mapGetters, mapActions } from 'vuex'
import { log } from '@/utils/helper'
import { queryToString, base64Password } from '@/utils/helper'
import validator from '@/utils/validation'
import { debounce } from 'lodash'
import GraphicalCode from '@/components/GraphicalCode'
import { downloadFile, dealJump, haveReportDown } from '../../../utils/reportDown'

export default {
  name: 'Home',
  components: {
    GraphicalCode,
  },
  data() {
    return {
      loading: false,
      shortTips: '2ad10e6',
      activeIndex: 0,
      needsData: {},
      aidData: {},
      loginType: 'userLoginByAccount',
      loginForm: {
        phone: '',
        account: '',
        graphical: '',
        short: '',
        password: '',
      },
      oldReportId: null
    }
  },
  computed: {
    ...mapGetters(['test']),
    rules() {
      return {
        phone: [
          { required: true, message: this.$t('6171f9a') },
          { validator: validator.phone },
        ],
        account: [{ required: true, message: this.$t('4704c2f') }],
        graphical: [
          { required: true, message: this.$t('46259e0') },
          { validator: validator.graphical },
        ],
        short: [
          { required: true, message: this.$t('0569ea7') },
          { validator: validator.short },
        ],
        password: [{ required: true, message: this.$t('6b45fda') }],
      }
    },
    showShortTips() {
      const tips = this.shortTips
      if (typeof tips === 'string') {
        return this.$t(tips)
      } else {
        return `${tips}s`
      }
    },
  },
  mounted() {
    this.handleTest()
  },
  methods: {
    ...mapActions(['LoginIn']),
    handleTest() {
      if (window.location.host === 'localhost:8080') {
        this.loginForm = {
          phone: '18883268737',
          // phone: '',
          // account: '18883268737',
          account: '13408642788',
          graphical: '',
          short: 123465,
          // password: 'qaz123456',
          password: 'w1234567',
        }
      }
    },
    // getVerify() {
    //   API.account
    //     .getVerify()
    //     .then((res) => {
    //       console.log('resresres', res)
    //       this.graphicalImg = res
    //       console.log('this.graphicalImg', this.graphicalImg)
    //     })
    //     .catch((error) => {
    //       console.log('error', error)
    //       log('Interface exception API.account.getVerify()')
    //     })
    // },
    checkVerify() {
      const data = {
        verifyInput: this.loginForm.graphical,
      }
      API.account
        .checkVerify(data)
        .then((res) => {
          this.changeShortTips()
        })
        .catch((error) => {
          console.log('error', error)
          log('Interface exception API.account.checkVerify()')
        })
    },
    handleLoginFormItemError(item, message) {
      item.validateMessage = message
      item.validateState = 'error'
    },
    refreshGraphical() {
      // const item = this.$refs['loginForm'].fields[1]
      this.$refs.GraphicalCode.refresh()
      // this.handleLoginFormItemError(item, 'dudududu')
    },
    handleShortTips: debounce(function() {
      if (typeof this.shortTips === 'string') {
        this.$refs.loginForm.validateField(['phone'], (verifycode_check) => {
          if (!verifycode_check) {
            this.changeShortTips()
            this.sendSms()
          }
        })
      }
    }, 500),
    changeShortTips() {
      this.shortTips = 60
      const timeoutId = setInterval(() => {
        if (this.shortTips > 0) {
          this.shortTips -= 1
        }
        if (this.shortTips === 0) {
          this.shortTips = '2ad10e6'
          clearTimeout(timeoutId)
        }
      }, 1000)
    },
    sendSms() {
      API.account
        .sendSms({
          phone: this.loginForm.phone,
          smsType: 'LOGIN',
        })
        .then((res) => {
          if (res.code === '000000') {
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          log('Interface exception API.account.sendSms()')
        })
    },
    goReset() {
      this.$router.push('/account/reset')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this[this.loginType]()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    queryToString(query) {
      return queryToString(query)
    },
    handleLogin(data) {
      this.loading = true
      this.LoginIn({
        data,
        loginType: this.loginType,
      }).then((res) => {
        this.loading = false
        if (res.accessToken) {
          const query = this.$route.query
          const path = query.redirect || '/home'
          // const str = this.queryToString(query)
          // if (str) path = path + '?' + str
          // console.log('path', path)
          this.$message.success('登录成功')
          this.getReportResult()
          this.$router.push({
            path,
          })
        } else {
          this.refreshGraphical()
          this.$message.error(res.message)
        }
      })
    },
    userLoginByAccount() {
      const form = this.loginForm
      const data = {
        code: form.graphical,
        password: base64Password(form.password),
        username: form.account,
      }
      this.handleLogin(data)
    },
    userLoginByPhone() {
      const form = this.loginForm
      const data = {
        phone: form.phone,
        code: form.graphical,
        smsCode: form.short,
      }
      this.handleLogin(data)
    },
    resetForm() {
      this.$refs['loginForm'].resetFields()
    },

    changeActive(index) {
      this.resetForm()
      this.activeIndex = index
      if (index === 1) {
        this.loginType = 'userLoginByPhone'
      } else {
        this.loginType = 'userLoginByAccount'
      }
    },
    goRegister() {
      this.$router.push({
        path: '/account/register',
      })
    },

    // 添加报告轮询
    showNotification(data) {
      const _this = this
      _this.$notify({
        customClass: 'reportNotify',
        message: <div>{data.name}报告已提交请点击此处<a onclick={() => {
          downloadFile(data, (data) => {
            haveReportDown(data.id, this.getReportResult)
          })
        }}>下载企业报告。</a><div>企业报告记录请到<a onclick={() => { this.oldReportId = null; dealJump('/usercenter/download') }}>个人中心-我的下载</a>查看。</div></div>,
        duration: 0,
        onClick: () => {
          this.oldReportId = null
          const storyTimeoutId = sessionStorage.getItem('timeoutId')
          storyTimeoutId && clearTimeout(storyTimeoutId)
          sessionStorage.setItem('timeoutId', '')
        }
      })
    },
    getReportResult() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      this.timeoutId = setInterval(() => {
        API.common.getReport().then(res => {
          if (res && res?.url) {
            // clearTimeout(this.timeoutId)
            // sessionStorage.setItem('timeoutId', '')
            if (res?.id !== this.oldReportId) {
              this.oldReportId = res?.id
              this.showNotification(res)
            }
          }
        })
      }, 5000)
      sessionStorage.setItem('timeoutId', this.timeoutId)
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.login {
  min-height: calc(100vh - 244px);
  padding-top: 180px;
  box-sizing: border-box;
  .container {
    display: flex;
    width: @uni-width;
    margin: 0 auto;
    // margin-top: 180px;
  }
  .lt {
    width: 802px;
    height: 458px;
    margin-right: 20px;
    background: url('~@/assets/account/login.png') center no-repeat;
    background-size: 802px 458px;
    .legalAidInfo {
      margin-top: 20px;
    }
  }
  .rt {
    .hd {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;
      font-size: 18px;
      color: #999999;
      white-space: nowrap;
      div {
        cursor: pointer;
      }
      div:first-child {
        margin-right: 60px;
      }
      .active {
        position: relative;
        color: @uni-text-color-select;
        &::after {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -8px;
          content: '';
          width: 100%;
          height: 2px;
          background: @uni-text-color-select;
        }
      }
    }
    .bd {
      display: flex;
      flex-direction: column;
      align-items: center;

      .loginForm {
        width: 360px;
        & /deep/ .el-form-item {
          margin-bottom: 40px;
        }
        & /deep/ .reset {
          position: relative;
          top: -20px;
          text-align: right;
          cursor: pointer;
          color: #00a4ff;
        }
        & /deep/ .loginButton {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: 310px;
          height: 45px;
          background: #00a4ff;
          border-radius: 23px;
        }
        & /deep/ .el-input__inner {
          border-radius: 23px;
        }
        .graphical {
          position: relative;
          .uiImg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 80px;
            height: 34px;
            padding: 4px 0 2px 10px;
            margin-right: 10px;
            border-left: 2px solid #efefef;
            border-radius: 0 18px 18px 0;
            cursor: pointer;
          }
        }
        .short {
          position: relative;
          .uiText {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            padding: 0 10px;
            border-left: 2px solid #efefef;
            font-size: 14px;
            color: #cccccc;
            text-align: center;
            cursor: pointer;
          }
        }
      }

      .des {
        display: flex;
        margin-top: 24px;
        font-size: 14px;
        color: #8f8f8f;
        .link {
          color: @uni-text-color-select;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
